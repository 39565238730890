let x = [[64.1253595,-21.88353062], [64.1238022,-21.88683162], [64.12342193,-21.88335312],
[64.12263173,-21.8758421], [64.1222754,-21.8743589], [64.12193259,-21.87486697],
[64.1230127,-21.88184179], [64.12141106,-21.86541328], [64.12111453,-21.86438685],
[64.11814378,-21.85766837], [64.11776584,-21.85729529], [64.11702758,-21.85872981],
[64.1170989,-21.8600134], [64.11707858,-21.86354821], [64.11756276,-21.87129724],
[64.11798758,-21.87447428], [64.11837792,-21.87880221], [64.11790122,-21.88057832],
[64.11808436,-21.88499811], [64.11828137,-21.88819015], [64.11981055,-21.88907544],
[64.11986308,-21.89271748], [64.12014431,-21.88600686], [64.11903314,-21.87951801],
[64.1212706,-21.8769867], [64.11920203,-21.88438468], [64.12225397,-21.87518304],
[64.11970074,-21.8780983], [64.11902849,-21.87308897], [64.11912397,-21.87402753],
[64.11885089,-21.86455696], [64.11803029,-21.86252574], [64.12496549,-21.89659548],
[64.12492648,-21.89792134], [64.12362034,-21.89207129], [64.12362361,-21.89354388],
[64.12362541,-21.89437319], [64.12355083,-21.8907199], [64.12310602,-21.89143682],
[64.12587862,-21.88841547], [64.12585736,-21.88783908], [64.12688796,-21.88807647],
[64.1289449,-21.88509178], [64.12809439,-21.88837059], [64.12778125,-21.89232254],
[64.12844854,-21.89567624], [64.12817394,-21.89538982], [64.12924267,-21.89739676],
[64.12543541,-21.89661718], [64.13068347,-21.88038596], [64.13139906,-21.88300768],
[64.13151674,-21.88367093], [64.13106355,-21.88768468], [64.13361886,-21.87829745],
[64.13466003,-21.87999438], [64.13705464,-21.88203125], [64.13857402,-21.88325824],
[64.13786385,-21.8908906], [64.13773834,-21.89171789], [64.1331909,-21.8959131],
[64.13475207,-21.89028129], [64.13282531,-21.88433195], [64.13350356,-21.88529613],
[64.13489456,-21.88728425], [64.13342758,-21.88263138], [64.14014341,-21.88809493],
[64.13957698,-21.88723998], [64.11999279,-21.86713294], [64.12091877,-21.86515005],
[64.13560947,-21.87348756], [64.13805101,-21.87773614], [64.12730298,-21.8727284],
[64.12635366,-21.85776177], [64.12852773,-21.87064028], [64.1284899,-21.87173819],
[64.13196663,-21.88940884], [64.13226205,-21.89386569], [64.12038115,-21.85270886],
[64.12053311,-21.85494496], [64.11834977,-21.85118088], [64.11875393,-21.84845108],
[64.11725169,-21.84714528], [64.12102587,-21.85685351], [64.12083848,-21.86029425],
[64.12045072,-21.85854471], [64.12219516,-21.85421465], [64.12299418,-21.85353294],
[64.12314331,-21.85003287], [64.12619258,-21.86430577], [64.12668271,-21.86768075],
[64.12286581,-21.86455736], [64.12395899,-21.86616349], [64.12399727,-21.8675453],
[64.12422189,-21.86917061], [64.1236324,-21.87030913], [64.12378223,-21.87355434],
[64.12420479,-21.874958], [64.12439962,-21.87325178], [64.12320862,-21.87666222],
[64.12444502,-21.87952709], [64.1247466,-21.87877876], [64.1245577,-21.8774284],
[64.12701858,-21.87197665], [64.12744112,-21.87293503], [64.1303798,-21.87515978],
[64.12599853,-21.87779192], [64.1268793,-21.88022657], [64.12912416,-21.88105467],
[64.12813806,-21.88445929], [64.125588,-21.88522724], [64.12512117,-21.88178091],
[64.09615382,-21.85431958], [64.20905999,-21.71623915], [64.20906178,-21.71515832],
[64.20907256,-21.71513217], [64.24068582,-21.82801298], [64.24208671,-21.83062889],
[64.24219681,-21.83066976], [64.24186745,-21.82996885], [64.24170375,-21.83169796],
[64.24106207,-21.83362184], [64.24036223,-21.83288517], [64.24054071,-21.8365839],
[64.24189425,-21.83843544], [64.24180507,-21.83407805], [64.23945098,-21.83525206],
[64.23992532,-21.83351682], [64.24018297,-21.83327626], [64.23902403,-21.83089869],
[64.24089046,-21.82885327], [64.23932873,-21.8295611], [64.23701256,-21.82535387],
[64.23815522,-21.8286016], [64.23792426,-21.83063614], [64.1419981,-21.92060785],
[64.14266123,-21.92031245], [64.14316109,-21.92312462], [64.14267727,-21.92327656],
[64.14455101,-21.92988189], [64.14454228,-21.93326745], [64.14297289,-21.9362366],
[64.14052316,-21.93537963], [64.14157986,-21.9344475], [64.12971832,-21.92481823],
[64.12537352,-21.9193948], [64.13285418,-21.88306378], [64.12947794,-21.8883556],
[64.12775183,-21.87379061], [64.12713751,-21.87352439], [64.11181714,-21.83393569],
[64.10811119,-21.83111694], [64.10660447,-21.81087931], [64.12287963,-21.81882814],
[64.11588255,-21.80468914], [64.11503915,-21.79343086], [64.11139443,-21.78427993],
[64.10549808,-21.77996558], [64.1365376,-21.80691037], [64.13465759,-21.79780862],
[64.13490478,-21.7981948], [64.13555837,-21.79309213], [64.13668937,-21.79239295],
[64.12419279,-21.7359004], [64.12522393,-21.73247542], [64.12561727,-21.76760339],
[64.12455176,-21.7633214], [64.12462719,-21.75352519], [64.13336678,-21.85695175],
[64.13302092,-21.85819209], [64.14516214,-21.8578276], [64.15175192,-21.79665315],
[64.15411525,-21.79622331], [64.1520548,-21.80146778], [64.1538013,-21.80077037],
[64.15265021,-21.80195239], [64.14072859,-21.79353554], [64.14114894,-21.79504495],
[64.14292368,-21.79339887], [64.11550079,-21.79171168], [64.13310803,-21.81878722],
[64.12737501,-21.75838378], [64.14271811,-21.8600414], [64.13161334,-21.7583929],
[64.1327365,-21.74908791], [64.13316714,-21.73769407], [64.12896285,-21.76589587],
[64.1241449,-21.74444061], [64.13430278,-21.7394303], [64.12879466,-21.73581795],
[64.12825281,-21.7437308], [64.12732556,-21.73079441], [64.13006813,-21.73069274],
[64.12123258,-21.76237603], [64.11064965,-21.78481256], [64.10992669,-21.78479129],
[64.10916954,-21.80665667], [64.10853368,-21.8058504], [64.14072915,-21.94178442],
[64.11827947,-21.85704916], [64.13910514,-21.85940477], [64.13484134,-21.86336292],
[64.13274949,-21.86093916], [64.13979329,-21.8571617], [64.13743476,-21.85858886],
[64.1093817,-21.83354191], [64.14480448,-21.76746594], [64.14498733,-21.77553591],
[64.15119669,-21.77454055], [64.14930278,-21.78742146], [64.14383584,-21.79439045],
[64.14222918,-21.79258244], [64.11777768,-21.90017483], [64.1271038,-21.7656823],
[64.12664463,-21.75732829], [64.12696694,-21.75223039], [64.12603826,-21.74168128],
[64.1299466,-21.73938005], [64.13120048,-21.7374885], [64.13048925,-21.75165555],
[64.10137308,-21.77442668], [64.10343943,-21.77560494], [64.10281595,-21.77656899],
[64.10238339,-21.77107626], [64.1008497,-21.76591793], [64.12385415,-21.92390005],
[64.09945026,-21.76963665], [64.09652634,-21.77783095], [64.10397374,-21.84226706],
[64.10300245,-21.83533906], [64.09825128,-21.83796435], [64.0954055,-21.84321774],
[64.13364726,-21.90660678], [64.13260419,-21.89959269], [64.12954509,-21.90264459],
[64.13060268,-21.9079143], [64.14096106,-21.94168152], [64.13844895,-21.91603703],
[64.13828231,-21.91446802], [64.13683866,-21.91079759], [64.13634408,-21.91300083],
[64.13662877,-21.91685803], [64.13599548,-21.85071158], [64.14037756,-21.96120515],
[64.15124329,-21.95922975], [64.15079825,-21.95320777], [64.14972853,-21.95206179],
[64.14759,-21.95710716], [64.14759,-21.95710716], [64.14420077,-21.95476645],
[64.1507292,-21.94541652], [64.141696,-21.96140043], [64.1054245,-21.81992926],
[64.0996078,-21.84604455], [64.10177374,-21.83378744], [64.12963999,-21.79032412],
[64.12772463,-21.79931551], [64.14524834,-21.78225773], [64.13321363,-21.8136198],
[64.13248504,-21.81447226], [64.13660893,-21.76107619], [64.10441539,-21.81477152],
[64.141188,-21.79972507], [64.13845564,-21.80635369], [64.10768211,-21.84329801],
[64.14267373,-21.79475664], [64.14437754,-21.79306303], [64.14684894,-21.78841857],
[64.13429151,-21.74691248], [64.1571378,-21.76606805], [64.0992047,-21.77365271],
[64.14333574,-21.91413676], [64.14337879,-21.91402446], [64.14280963,-21.91368778],
[64.143271,-21.9166133], [64.13323735,-21.86185367], [64.13324339,-21.86187279],
[64.13391573,-21.86714074], [64.13351036,-21.86718004], [64.13120117,-21.86420704],
[64.1089597,-21.79324737], [64.10568737,-21.78666113], [64.10785465,-21.78476204],
[64.10518572,-21.77811283], [64.10244491,-21.78010457], [64.11169853,-21.78152881],
[64.11725017,-21.84289412], [64.11889591,-21.84540749], [64.11936917,-21.84549359],
[64.12088121,-21.84501814], [64.11710157,-21.82824937], [64.11632574,-21.82878023],
[64.11627911,-21.8288481], [64.116262,-21.82829421], [64.09391811,-21.77202038],
[64.09825917,-21.77129904], [64.09949775,-21.76580972], [64.14378337,-21.76182751],
[64.12886774,-21.82382459], [64.12420951,-21.82374007], [64.12424246,-21.82229783],
[64.1242387,-21.82213761], [64.12456554,-21.81251121], [64.12484846,-21.81222544],
[64.1256103,-21.81369847], [64.13370209,-21.81427574], [64.13241427,-21.81513734],
[64.13164718,-21.81533031], [64.13165253,-21.81525092], [64.13073277,-21.81809516],
[64.12646697,-21.84346384], [64.12623271,-21.84187366], [64.12894782,-21.84179544],
[64.12797553,-21.77732058], [64.12429806,-21.82374663], [64.14324663,-21.91456011],
[64.14344488,-21.91467478], [64.14350341,-21.91426379], [64.14312739,-21.91622879],
[64.14376993,-21.91915726], [64.14364978,-21.9190009], [64.14395418,-21.92018974],
[64.14423211,-21.92194103], [64.14423544,-21.92167712], [64.14449569,-21.92293269],
[64.14466221,-21.92383491], [64.14483057,-21.92496388], [64.14493354,-21.92512389],
[64.14507386,-21.92586371], [64.14523862,-21.92701023], [64.14538694,-21.92747987],
[64.14570682,-21.92933014], [64.14572835,-21.92914688], [64.14591538,-21.93033143],
[64.14610851,-21.93119397], [64.14617705,-21.93129376], [64.14627684,-21.93179283],
[64.14644354,-21.93265692], [64.14767957,-21.93860065], [64.14834796,-21.94102669],
[64.14833325,-21.94093206], [64.11915174,-21.81524974], [64.11945027,-21.8134243],
[64.11941494,-21.81506256], [64.12204793,-21.83178908], [64.12062392,-21.82028219],
[64.12078424,-21.81675998], [64.12071375,-21.81418678], [64.10141974,-21.76180205],
[64.10079294,-21.76332121], [64.09980458,-21.77521613], [64.09826183,-21.77681781],
[64.09760614,-21.77970655], [64.1004319,-21.77822739], [64.10142883,-21.77801879],
[64.10144318,-21.77830976], [64.10261091,-21.76685929], [64.12414037,-21.7950153],
[64.1237653,-21.82181496], [64.12376841,-21.82178972], [64.12068384,-21.808775],
[64.11981355,-21.80782496], [64.12031928,-21.81113603], [64.11939064,-21.81211126],
[64.1190154,-21.80840873], [64.11899262,-21.80365599], [64.11802239,-21.79780061],
[64.11581361,-21.78560232], [64.11695272,-21.80168124], [64.11733475,-21.80404749],
[64.11791987,-21.80855542], [64.11797797,-21.80982109], [64.11866328,-21.81313499],
[64.11654635,-21.8103323], [64.11498616,-21.80856517], [64.11459104,-21.80521566],
[64.11614706,-21.80758911], [64.11649479,-21.79939321], [64.14731396,-21.80164355],
[64.14794435,-21.79118454], [64.14816846,-21.79184817], [64.14940306,-21.79204912],
[64.15428196,-21.78526986], [64.15273675,-21.78649668], [64.15307039,-21.79171339],
[64.14861278,-21.77904775], [64.14710539,-21.77932793], [64.14796978,-21.77300088],
[64.14581998,-21.78316467], [64.14491605,-21.7705576], [64.14491221,-21.76779265],
[64.14286073,-21.76598699], [64.14281674,-21.76272482], [64.1482223,-21.77198841],
[64.14879275,-21.78625093], [64.147644,-21.78623545], [64.14691147,-21.7878422],
[64.14242804,-21.79229649], [64.14192718,-21.79412248], [64.14167347,-21.79916366],
[64.13653021,-21.8095205], [64.13516481,-21.81125955], [64.13425933,-21.79966832],
[64.13443227,-21.79959691], [64.13087709,-21.79108691], [64.13159373,-21.79583126],
[64.13269062,-21.80417203], [64.13245361,-21.81371101], [64.14789144,-21.93647661],
[64.14819178,-21.94140123], [64.14795633,-21.94162317], [64.14801558,-21.94157172],
[64.14798491,-21.94113165], [64.14812893,-21.94098452], [64.14784396,-21.94124209],
[64.14790526,-21.94118333], [64.1480704,-21.94153069], [64.14444853,-21.91888307],
[64.14457508,-21.9198947], [64.14409003,-21.91750289], [64.14443896,-21.91825289],
[64.14525307,-21.93065905], [64.12802607,-21.9184095], [64.12993421,-21.9273533],
[64.13073093,-21.92962747], [64.14247574,-21.92534305], [64.14243371,-21.92527176],
[64.14176674,-21.92801218], [64.14121485,-21.92693366], [64.14116309,-21.92584717],
[64.14856726,-21.92584222], [64.14767581,-21.93474322], [64.1420369,-21.91784571],
[64.14246561,-21.91699228], [64.12665321,-21.84593324], [64.14890427,-21.88574823],
[64.14915771,-21.8851198], [64.15096947,-21.88262991], [64.15145899,-21.88230761],
[64.15066802,-21.87502562], [64.15064038,-21.87470111], [64.14896295,-21.86745787],
[64.14519157,-21.85747045], [64.14136997,-21.85385596], [64.14151786,-21.85356426],
[64.13949605,-21.85267878], [64.11582433,-21.7993351], [64.11590798,-21.79869001],
[64.11549595,-21.80041432], [64.11472256,-21.80112726], [64.11397474,-21.79809511],
[64.1116034,-21.79770813], [64.11111421,-21.79750652], [64.11065185,-21.79729726],
[64.11026582,-21.79584921], [64.10809269,-21.79667881], [64.10085149,-21.80108572],
[64.09872795,-21.79931458], [64.09749932,-21.79823184], [64.11959281,-21.81448598],
[64.11981202,-21.81386342], [64.1186074,-21.81393356], [64.11948464,-21.82358365],
[64.12004803,-21.82842894], [64.11672209,-21.81580959], [64.11610164,-21.8163835],
[64.11753242,-21.81469816], [64.11645306,-21.8142969], [64.11631074,-21.79704864],
[64.11617753,-21.79641937], [64.11518374,-21.79045603], [64.11154048,-21.79324836],
[64.11209672,-21.79419868], [64.11234762,-21.79406081], [64.11537296,-21.78923423],
[64.11438666,-21.78441146], [64.11087116,-21.79067159], [64.11321061,-21.78857022],
[64.11399018,-21.78838979], [64.11158327,-21.78555269], [64.10975964,-21.78692527],
[64.10941244,-21.78551559], [64.10864099,-21.78239576], [64.10804649,-21.78822439],
[64.13417489,-21.81001261], [64.13433678,-21.81243389], [64.13327056,-21.78855227],
[64.13414322,-21.79445486], [64.13435547,-21.79420865], [64.13380045,-21.79337785],
[64.13708298,-21.8017934], [64.13755697,-21.80180587], [64.13920867,-21.80192672],
[64.13843085,-21.79446153], [64.13896815,-21.79212015], [64.12790572,-21.78740332],
[64.12802821,-21.78756848], [64.12850792,-21.78831735], [64.1275995,-21.79113013],
[64.12837851,-21.78220273], [64.13996546,-21.85095551], [64.1402021,-21.85073518],
[64.13358217,-21.85022954], [64.1348858,-21.84964701], [64.12671692,-21.8574726],
[64.12896475,-21.87093872], [64.1300266,-21.86360897], [64.1298454,-21.86304259],
[64.12916658,-21.87079418], [64.13031311,-21.87457042], [64.13345057,-21.86897632],
[64.12843586,-21.86111425], [64.12704357,-21.8570518], [64.1268855,-21.85474348],
[64.12656031,-21.85254471], [64.12743414,-21.85415661], [64.14106367,-21.86105227],
[64.14066921,-21.86158911], [64.13888814,-21.85919603], [64.13876517,-21.85945956],
[64.13607859,-21.85636738], [64.1360902,-21.85681431], [64.12948278,-21.85340361],
[64.12927284,-21.85014837], [64.12979904,-21.86057852], [64.12885128,-21.85804636],
[64.12827913,-21.85635951], [64.13208243,-21.85032045], [64.12917882,-21.85697711],
[64.13060701,-21.86106343], [64.13124096,-21.86067956], [64.13126678,-21.86139191],
[64.13391904,-21.8668607], [64.14217378,-21.95852765], [64.14176536,-21.9573155],
[64.14163795,-21.9588727], [64.14070798,-21.95807136], [64.14119843,-21.95949328],
[64.14187074,-21.96272147], [64.14183072,-21.96284285], [64.14332743,-21.96097306],
[64.14853104,-21.96305927], [64.14783011,-21.96257876], [64.1479532,-21.96009785],
[64.14720663,-21.9636689], [64.14706281,-21.96369501], [64.14689626,-21.96537488],
[64.14603387,-21.96550342], [64.1465247,-21.96304586], [64.14691256,-21.96108236],
[64.14605544,-21.96467476], [64.14792285,-21.97060433], [64.1495676,-21.97180657],
[64.14620449,-21.9701618], [64.14625165,-21.97135066], [64.14612868,-21.9715131],
[64.14572739,-21.96883252], [64.14493389,-21.97070974], [64.14493295,-21.97153774],
[64.14470371,-21.97113107], [64.14273339,-21.97080572], [64.14264139,-21.96538238],
[64.14387649,-21.96630868], [64.14917461,-21.981064], [64.15057333,-21.98163466],
[64.14985819,-21.97654811], [64.14831863,-21.97616725], [64.14988692,-21.96740907],
[64.15129719,-21.95750762], [64.14610055,-21.89612516], [64.14399643,-21.90211],
[64.14511934,-21.9104131], [64.13694471,-21.81040685], [64.13693247,-21.81287151],
[64.1375899,-21.81439314], [64.13943452,-21.81835643], [64.13475239,-21.74571246],
[64.13551094,-21.7432847], [64.1358574,-21.73482924], [64.13656338,-21.72934073],
[64.13655674,-21.7292838], [64.13633096,-21.74077453], [64.10897273,-21.76440506],
[64.11138612,-21.77610268], [64.11108279,-21.77985089], [64.10617869,-21.77702548],
[64.11442186,-21.77506092], [64.10324387,-21.76572113], [64.102895,-21.76415315],
[64.12869108,-21.74127845], [64.12999195,-21.7475435], [64.13032091,-21.76185566],
[64.12447338,-21.75797458], [64.12345616,-21.75682797], [64.12300826,-21.75488429],
[64.12194212,-21.74642946], [64.12712369,-21.76733993], [64.12733174,-21.7659182],
[64.12709575,-21.76243904], [64.12683809,-21.75751657], [64.12674093,-21.75055286],
[64.12710438,-21.75195763], [64.12766999,-21.74838888], [64.12764495,-21.74762709],
[64.12649454,-21.75103424], [64.124773,-21.75083212], [64.12284526,-21.75206864],
[64.1228374,-21.74917778], [64.126348,-21.74436012], [64.12595821,-21.74011014],
[64.12672741,-21.73714684], [64.12445712,-21.73793133], [64.12625438,-21.73498766],
[64.13220465,-21.86568736], [64.13745069,-21.85871881], [64.13603343,-21.86792309],
[64.13393597,-21.85049879], [64.13601666,-21.85352484], [64.13765156,-21.85140637],
[64.13702021,-21.85127091], [64.14345442,-21.8695063], [64.14678296,-21.87231741],
[64.14692167,-21.87033345], [64.14676599,-21.86713531], [64.1465914,-21.86677234],
[64.14346514,-21.86461161], [64.1440172,-21.86459426], [64.14517489,-21.86418058],
[64.14544972,-21.86383699], [64.14648873,-21.86563811], [64.14456364,-21.86315632],
[64.14480714,-21.86598906], [64.13649949,-21.87358612], [64.13655543,-21.87180115],
[64.13701227,-21.8704468], [64.139917,-21.87262441], [64.14030161,-21.87262315],
[64.14031401,-21.8714986], [64.14033778,-21.87110125], [64.14011542,-21.87339643],
[64.14089458,-21.8751712], [64.14102297,-21.87829561], [64.14060939,-21.87752482],
[64.14059478,-21.87659774], [64.13909195,-21.87840509], [64.14182359,-21.88529006],
[64.14462335,-21.89028873], [64.14669392,-21.88697946], [64.14391143,-21.89277559],
[64.14051652,-21.89115679], [64.14348948,-21.88092901], [64.13979418,-21.81208094],
[64.13946358,-21.81314194], [64.13856374,-21.8084996], [64.14291705,-21.80710833],
[64.14169288,-21.81711694], [64.13976165,-21.81895213], [64.13870843,-21.82201408],
[64.13778468,-21.82119677], [64.13715589,-21.81975627], [64.13607758,-21.81576374],
[64.13490024,-21.81463061], [64.13570732,-21.81246203], [64.14154803,-21.82130814],
[64.14055217,-21.82327563], [64.13943252,-21.81450369], [64.14142392,-21.80879919],
[64.14298738,-21.80618155], [64.1445944,-21.79750034], [64.14592429,-21.7982973],
[64.14333877,-21.7987538], [64.14286009,-21.80766648], [64.14337527,-21.8101358],
[64.1435692,-21.80830545], [64.14819461,-21.80483353], [64.16082074,-21.79078836],
[64.15774872,-21.78989249], [64.15633487,-21.79003515], [64.15614103,-21.7842516],
[64.15593411,-21.77764717], [64.15650937,-21.77430313], [64.15552225,-21.77017506],
[64.16140073,-21.75356968], [64.16074333,-21.7557282], [64.16420234,-21.74930967],
[64.12696153,-21.73699196], [64.12575942,-21.76066346], [64.12553512,-21.76615752],
[64.12840879,-21.75920621], [64.12804863,-21.75349296], [64.12841077,-21.75922674],
[64.1281591,-21.75683065], [64.12979104,-21.75594114], [64.11168664,-21.77921292],
[64.12180888,-21.76203494], [64.12241052,-21.7647171], [64.12424945,-21.76869718],
[64.12628036,-21.77406564], [64.1272892,-21.77287897], [64.12966985,-21.94982254],
[64.12967504,-21.95409906], [64.12903843,-21.9540882], [64.12880027,-21.95076754],
[64.13393368,-21.95746381], [64.13291299,-21.95298251], [64.13359766,-21.95166079],
[64.13475203,-21.94869083], [64.14329058,-21.94780422], [64.14364652,-21.94893558],
[64.14617365,-21.95397263], [64.14605892,-21.95422751], [64.14838401,-21.95797695],
[64.1503236,-21.96001265], [64.1499231,-21.95979361], [64.14441231,-21.95168564],
[64.14390897,-21.95083009], [64.14377791,-21.95065067], [64.14353857,-21.95108046],
[64.14349075,-21.95144424], [64.14225345,-21.95340237], [64.14246712,-21.95093233],
[64.14193768,-21.95509774], [64.14380588,-21.88093211], [64.14473419,-21.88090996],
[64.14497599,-21.87985058], [64.1449534,-21.87985149], [64.1454057,-21.8808561],
[64.14599476,-21.88056951], [64.1459796,-21.88043571], [64.14663072,-21.8798878],
[64.14682607,-21.87976509], [64.14640131,-21.88074016], [64.14928937,-21.87255579],
[64.1489685,-21.87296234], [64.14675181,-21.87501526], [64.14624662,-21.87402573],
[64.1479413,-21.87418354], [64.14662916,-21.87979721], [64.14753497,-21.88114404],
[64.14894344,-21.88056015], [64.14875388,-21.88091691], [64.1476333,-21.8835059],
[64.14695403,-21.88845614], [64.14698,-21.888738], [64.15547315,-21.86895922],
[64.15586676,-21.86648004], [64.15596071,-21.86687082], [64.15608303,-21.86715962],
[64.15612644,-21.86724687], [64.15622486,-21.86743564], [64.15562218,-21.86827635],
[64.13641817,-21.87414514], [64.13915036,-21.87933723], [64.1403437,-21.88970257],
[64.14452841,-21.89200866], [64.14630089,-21.90581289], [64.14579179,-21.9056202],
[64.14570767,-21.90441873], [64.14619559,-21.89632667], [64.15915308,-21.76081757],
[64.15339104,-21.77025319], [64.15107756,-21.76810077], [64.1482442,-21.77549459],
[64.13641907,-21.80688636], [64.1357804,-21.80218771], [64.13462671,-21.79806807],
[64.13473588,-21.79369534], [64.13790755,-21.79084057], [64.13922662,-21.7892428],
[64.13897992,-21.78700422], [64.13825027,-21.78600921], [64.13831308,-21.78601664],
[64.1385219,-21.7837323], [64.13513036,-21.78251974], [64.13493949,-21.78448028],
[64.13482828,-21.78738988], [64.13490899,-21.78797234], [64.13621219,-21.78861538],
[64.1378556,-21.78942799], [64.1348409,-21.77844083], [64.13821301,-21.77540733],
[64.13872197,-21.77668455], [64.13736431,-21.77875909], [64.13927786,-21.77508189],
[64.14062095,-21.77803783], [64.14412499,-21.77495275], [64.14432254,-21.78750642],
[64.14467454,-21.78630218], [64.14376716,-21.78952175], [64.14142324,-21.79007041],
[64.13968012,-21.80594131], [64.14075877,-21.80527747], [64.13922852,-21.79597321],
[64.14115659,-21.95405347], [64.14319272,-21.95724905], [64.14292451,-21.90695535],
[64.1451924,-21.91201372], [64.14374629,-21.91192813], [64.14378914,-21.9132447],
[64.1430914,-21.91248727], [64.14369723,-21.91499391], [64.14236479,-21.87672231],
[64.14231623,-21.87559622], [64.14209271,-21.8738761], [64.14246743,-21.87441532],
[64.1414133,-21.87255698], [64.14112448,-21.87092664], [64.14068315,-21.87073402],
[64.1408327,-21.87001233], [64.13944624,-21.87008169], [64.13872959,-21.86907479],
[64.13849362,-21.86892098], [64.13793732,-21.86956165], [64.13752131,-21.86982966],
[64.14351512,-21.87130649], [64.14391558,-21.87216415], [64.14269947,-21.86965592],
[64.142947,-21.87017811], [64.14085324,-21.86747228], [64.13976964,-21.86555652],
[64.13815152,-21.86434152], [64.13337397,-21.87242749], [64.14707275,-21.90890468],
[64.14758452,-21.89687697], [64.14893123,-21.89129728], [64.14134644,-21.8502571],
[64.14045372,-21.79042948], [64.13988902,-21.7884889], [64.1400463,-21.79414641],
[64.1398066,-21.79122105], [64.1526955,-21.76690322], [64.15444539,-21.76173039],
[64.15233534,-21.76454467], [64.10759455,-21.84587653], [64.10826113,-21.84490895],
[64.14119514,-21.92296721], [64.14182187,-21.9249982], [64.1420376,-21.92531405],
[64.14015625,-21.9269249], [64.14153949,-21.92769481], [64.14241967,-21.92733566],
[64.14212532,-21.92876766], [64.14259169,-21.92748534], [64.14255036,-21.92800812],
[64.14234327,-21.92892188], [64.14247497,-21.92841302], [64.14236131,-21.92574672],
[64.14180334,-21.93002099], [64.14286852,-21.93167232], [64.14210086,-21.93043102],
[64.14270674,-21.92999348], [64.14351034,-21.930891], [64.14421467,-21.93196823],
[64.14419061,-21.93179537], [64.14215691,-21.92875687], [64.14217006,-21.93091197],
[64.14375036,-21.92655651], [64.14402976,-21.92837197], [64.14359001,-21.92911239],
[64.14361922,-21.92937397], [64.14085025,-21.92541596], [64.14027893,-21.94223602],
[64.14117491,-21.94303434], [64.1414559,-21.94359311], [64.1422666,-21.94366575],
[64.14251831,-21.94369528], [64.14127785,-21.94136598], [64.14117034,-21.94056222],
[64.14146074,-21.94481505], [64.14055371,-21.94004108], [64.13995975,-21.93965089],
[64.15336752,-21.76433537], [64.1546292,-21.76486243], [64.15509639,-21.75842193],
[64.15550499,-21.75684321], [64.15772172,-21.75617594], [64.15970436,-21.75117376],
[64.15668145,-21.75103227], [64.15803858,-21.75092917], [64.16239126,-21.74769453],
[64.16235193,-21.745556], [64.15592413,-21.75302893], [64.15191297,-21.77447348],
[64.15451532,-21.77351195], [64.15501148,-21.78042623], [64.1530757,-21.78114181],
[64.15311985,-21.7764508], [64.15416402,-21.77788397], [64.15166896,-21.78009337],
[64.15044979,-21.77884666], [64.15262063,-21.7840043], [64.15148762,-21.78440495],
[64.15032987,-21.78478038], [64.15015327,-21.79146231], [64.1502337,-21.79097526],
[64.15020534,-21.78935712], [64.15014586,-21.79458089], [64.14923799,-21.80167225],
[64.14868367,-21.79535427], [64.15435912,-21.9536479], [64.15636181,-21.95367567],
[64.14544826,-21.95803179], [64.1446908,-21.96023815], [64.14459559,-21.96187033],
[64.14470876,-21.96222092], [64.14481718,-21.96228018], [64.14387753,-21.96246807],
[64.14212142,-21.96615022], [64.1405313,-21.96487721], [64.13923248,-21.96417605],
[64.1386746,-21.96352843], [64.13907364,-21.96321454], [64.13780572,-21.96268559],
[64.13624984,-21.96223727], [64.13671425,-21.96129933], [64.13489198,-21.96112335],
[64.13555025,-21.95609173], [64.13739443,-21.95458326], [64.13730976,-21.95414555],
[64.13667893,-21.95865409], [64.13973679,-21.95194453], [64.13877283,-21.95797314],
[64.13870446,-21.95926931], [64.13894285,-21.96012916], [64.13945088,-21.96235428],
[64.13969105,-21.96225855], [64.14581547,-21.94777834], [64.14585492,-21.94740274],
[64.14602054,-21.94811684], [64.14788455,-21.95563208], [64.15180495,-21.95166306],
[64.15155921,-21.95299834], [64.14954639,-21.94655871], [64.14802793,-21.94838979],
[64.14775731,-21.95102472], [64.14994455,-21.95863997], [64.15039895,-21.95753927],
[64.14966874,-21.95726341], [64.14885268,-21.95365917], [64.148262,-21.94448463],
[64.14920048,-21.94384534], [64.15085262,-21.9447957], [64.1487529,-21.94418639],
[64.14672591,-21.94769618], [64.14720692,-21.94784228], [64.14794043,-21.94869783],
[64.14777198,-21.95142196], [64.14672196,-21.9543213], [64.1467003,-21.95401714],
[64.12615207,-21.95050167], [64.12764999,-21.95531061], [64.13132671,-21.9590112],
[64.15147392,-21.9488215], [64.15117769,-21.94934973], [64.15105363,-21.95071807],
[64.15123572,-21.95165735], [64.14960413,-21.94349691], [64.1499673,-21.97166507],
[64.15009995,-21.97614708], [64.15058689,-21.9802178], [64.15002958,-21.96976913],
[64.15039867,-21.9436612], [64.09112282,-21.839711], [64.10349986,-21.86134346],
[64.13412483,-21.9037775], [64.13381768,-21.90379902], [64.13381064,-21.9038184],
[64.13601656,-21.91595388], [64.13576926,-21.91621615], [64.12724796,-21.90710718],
[64.12698232,-21.90711216], [64.12638922,-21.90733687], [64.13063019,-21.90043331],
[64.13070681,-21.90111406], [64.13074799,-21.90114558], [64.13070321,-21.90181938],
[64.13087045,-21.90206863], [64.12824992,-21.90472678], [64.13132889,-21.90719664],
[64.13119655,-21.90692653], [64.1296228,-21.91171693], [64.13019985,-21.91363494],
[64.13015987,-21.91445478], [64.13068219,-21.91058731], [64.13168582,-21.90385456],
[64.1334126,-21.91198723], [64.13350399,-21.91140429], [64.13344262,-21.91145493],
[64.13879898,-21.92143177], [64.13848116,-21.9212237], [64.13743642,-21.92118648],
[64.13908545,-21.91627418], [64.14026705,-21.91510926], [64.13837311,-21.91515779],
[64.13791757,-21.91618523], [64.13716756,-21.91476881], [64.13700914,-21.91324347],
[64.1364306,-21.91588882], [64.13659422,-21.91381007], [64.13662432,-21.9138845],
[64.13652232,-21.91324329], [64.13604896,-21.91232254], [64.13574444,-21.91161703],
[64.13668923,-21.91012249], [64.13718373,-21.91063874], [64.13743562,-21.91145884],
[64.13753428,-21.91200658], [64.13758354,-21.91225296], [64.1280181,-21.94683195],
[64.13211967,-21.94771666], [64.13555364,-21.94362365], [64.14604027,-21.91613256],
[64.14716228,-21.92168173], [64.14796126,-21.92402733], [64.13885962,-21.90929119],
[64.13862227,-21.90872598], [64.14140201,-21.9084337], [64.14136112,-21.90961356],
[64.13983152,-21.91115693], [64.14264041,-21.9064724], [64.13799142,-21.90382038],
[64.13921756,-21.89857747], [64.13723394,-21.89846659], [64.13730909,-21.89817804],
[64.13600952,-21.89849525], [64.13815081,-21.90413531], [64.13906671,-21.90342625],
[64.11114773,-21.8004433], [64.09780736,-21.80359216], [64.09840889,-21.80870373],
[64.13731325,-21.90842384], [64.13740515,-21.9088669], [64.13555676,-21.91038586],
[64.14308401,-21.91945058], [64.14509535,-21.92954079], [64.14376571,-21.92633273],
[64.14050668,-21.91954077], [64.14300414,-21.92661297], [64.14262878,-21.9268675],
[64.14277879,-21.92674577], [64.14213371,-21.92524441], [64.14266558,-21.92721412],
[64.14264144,-21.92780266], [64.14219136,-21.92923685], [64.13857759,-21.93440718],
[64.13758771,-21.92786187], [64.13737346,-21.92726583], [64.13860884,-21.93350507],
[64.13895903,-21.93615424], [64.13818405,-21.93673339], [64.13869677,-21.93214259],
[64.13884338,-21.93109148], [64.13897965,-21.9325376], [64.1429907,-21.9370917],
[64.14614015,-21.93482657], [64.14583695,-21.93424461], [64.14476087,-21.93507363],
[64.14408613,-21.93555651], [64.14301316,-21.9368731], [64.1457971,-21.93751023],
[64.1451356,-21.9379684], [64.14432957,-21.93178265], [64.14443095,-21.93152568],
[64.14358295,-21.93273237], [64.14358139,-21.93276267], [64.14234944,-21.93430444],
[64.14158353,-21.9330169], [64.14023776,-21.92460542], [64.0998472,-21.81167876],
[64.10173315,-21.81104723], [64.10310011,-21.81182276], [64.10253687,-21.80881516],
[64.10314185,-21.80567941], [64.10473105,-21.80485899], [64.10646952,-21.80241883],
[64.1073633,-21.8007362], [64.10893193,-21.79822448], [64.10956256,-21.8002912],
[64.11313728,-21.80775403], [64.11467764,-21.8162786], [64.11160844,-21.83385507],
[64.11157219,-21.82707831], [64.11029098,-21.82883535], [64.11016132,-21.82818957],
[64.11032512,-21.8346723], [64.11020947,-21.83452459], [64.10983674,-21.84169298],
[64.10970779,-21.84131564], [64.10971056,-21.84128386], [64.10806239,-21.83987703],
[64.10792602,-21.83794006], [64.10764743,-21.83475548], [64.10814831,-21.83286447],
[64.10776198,-21.83137232], [64.10938724,-21.82554163], [64.10540981,-21.82724377],
[64.10412123,-21.83045931], [64.10448924,-21.83451749], [64.10488535,-21.83524974],
[64.10473457,-21.83534293], [64.10593591,-21.83716955], [64.10674756,-21.84273571],
[64.10784957,-21.84516466], [64.10758772,-21.84511867], [64.10484383,-21.81625047],
[64.10542887,-21.81661968], [64.10547668,-21.81967641], [64.10554422,-21.81973329],
[64.10491213,-21.81830753], [64.1085118,-21.81683297], [64.10867183,-21.81691069],
[64.10870431,-21.81538304], [64.10792791,-21.81709285], [64.10807934,-21.81789141],
[64.10795834,-21.81791982], [64.10762129,-21.81754111], [64.10917098,-21.81909567],
[64.11005387,-21.81800209], [64.11063986,-21.81784932], [64.11085699,-21.81761817],
[64.11014308,-21.81708838], [64.10579575,-21.81995676], [64.10530804,-21.82017094],
[64.10492943,-21.8202568], [64.10454699,-21.82018422], [64.10437993,-21.82145546],
[64.10365848,-21.82158765], [64.10328113,-21.82556877], [64.10363973,-21.82490649],
[64.10548573,-21.82360456], [64.10547913,-21.82326161], [64.10518895,-21.82200752],
[64.10527998,-21.82233295], [64.14548666,-21.93957586], [64.14559372,-21.93990776],
[64.14569911,-21.940135], [64.14506506,-21.93955219], [64.14560004,-21.9397021],
[64.14603012,-21.94072575], [64.14612477,-21.94120053], [64.14481647,-21.93966231],
[64.14491861,-21.93925039], [64.14414538,-21.9395776], [64.10784914,-21.84548317],
[64.10824185,-21.84513564], [64.10824743,-21.84546766], [64.10898847,-21.84365034],
[64.10870115,-21.84365563], [64.10855447,-21.84372452], [64.10919749,-21.84364716],
[64.10939989,-21.84375621], [64.10936874,-21.84327484], [64.10937272,-21.84273761],
[64.1097223,-21.84374598], [64.10960475,-21.84459508], [64.10972076,-21.84230034],
[64.10972108,-21.84285136], [64.10958966,-21.84253952], [64.10959258,-21.8427175],
[64.10930182,-21.84226723], [64.10900585,-21.84220509], [64.10821746,-21.84210439],
[64.10814739,-21.84365199], [64.10821239,-21.84236706], [64.1082088,-21.84309115],
[64.10778306,-21.8410931], [64.10816708,-21.84058322], [64.10856582,-21.82412334],
[64.10330453,-21.83234425], [64.10293671,-21.8315479], [64.09794304,-21.8239031],
[64.09712589,-21.82639176], [64.09707617,-21.82833355], [64.09629737,-21.82780582],
[64.09634114,-21.82743882], [64.09775114,-21.82791988], [64.09451662,-21.8314139],
[64.09487058,-21.83082222], [64.09460179,-21.83730228], [64.09470625,-21.83772162],
[64.09437196,-21.83544599], [64.13994281,-21.93958435], [64.14054685,-21.93941466],
[64.14094275,-21.93939833], [64.14152583,-21.94047031], [64.14172786,-21.94039637],
[64.14211443,-21.94064293], [64.14246412,-21.94047156], [64.14259689,-21.93991287],
[64.14188103,-21.94684275], [64.14197157,-21.94694129], [64.14358157,-21.94455524],
[64.14040022,-21.95063623], [64.14900607,-21.94186312], [64.14923817,-21.9412614],
[64.14951215,-21.94191056], [64.14769632,-21.94436688], [64.12220644,-21.9281521],
[64.12187789,-21.92811416], [64.12206427,-21.92804874], [64.12236616,-21.92776092],
[64.1223885,-21.92713606], [64.12193496,-21.93041201], [64.12197111,-21.92961722],
[64.12475189,-21.92797841], [64.12813026,-21.9271284], [64.12034326,-21.90121099],
[64.12036886,-21.90133219], [64.12082834,-21.90489588], [64.12085745,-21.9094191],
[64.12149743,-21.91810715], [64.12198213,-21.91748081], [64.12221813,-21.92197197],
[64.12250312,-21.92396513], [64.12239628,-21.92545172], [64.12203139,-21.92894342],
[64.12168085,-21.932075], [64.0927978,-21.83674818], [64.09282002,-21.83622906],
[64.0931263,-21.83371085], [64.09124869,-21.83824606], [64.09394939,-21.84175627],
[64.09370486,-21.84131491], [64.09444431,-21.84059035], [64.09458668,-21.84011567],
[64.09604937,-21.84542842], [64.09605786,-21.84517838], [64.09972037,-21.85400253],
[64.10057258,-21.84760684], [64.10148437,-21.84690627], [64.1005235,-21.85729242],
[64.100697,-21.85740868], [64.10092014,-21.86036424], [64.09874663,-21.85271421],
[64.09636611,-21.85188602], [64.09850004,-21.85198254], [64.09799179,-21.84969073],
[64.09809998,-21.84682265], [64.09818273,-21.84687952], [64.09792033,-21.85841247],
[64.095878,-21.85051741], [64.09480806,-21.85022116], [64.09243154,-21.84372613],
[64.09759944,-21.84241967], [64.09756813,-21.84339547], [64.09685627,-21.84339784],
[64.09709984,-21.84242948], [64.09807567,-21.8440806], [64.09709212,-21.84211234],
[64.09655456,-21.8414951], [64.09620878,-21.8382213], [64.09676778,-21.8398691],
[64.10031924,-21.84023004], [64.09711825,-21.83918969], [64.1040187,-21.83651313],
[64.12070243,-21.93447118], [64.12047344,-21.93678642], [64.12101963,-21.93838578],
[64.12313275,-21.94099787], [64.12496751,-21.94689198], [64.14322763,-21.92860118],
[64.1431975,-21.92881017], [64.14444434,-21.93028783], [64.14447909,-21.93053093],
[64.14501213,-21.93126282], [64.14522897,-21.93134843], [64.145335,-21.93144696],
[64.14569248,-21.9320319], [64.14555626,-21.93199422], [64.1460513,-21.93254219],
[64.14644917,-21.93299453], [64.1466516,-21.93348749], [64.14714735,-21.93572212],
[64.14701867,-21.93528399], [64.14703583,-21.93592546], [64.14690411,-21.93563044],
[64.14758308,-21.93619378], [64.14745623,-21.93630782], [64.14754463,-21.93613113],
[64.14747134,-21.93635895], [64.14769773,-21.93588672], [64.14720401,-21.93662202],
[64.14731895,-21.9357359], [64.14738514,-21.93642007], [64.14980743,-21.93271176],
[64.14969281,-21.93270502], [64.14992193,-21.93271251], [64.1493467,-21.93012635],
[64.14816944,-21.93250452], [64.14781678,-21.93296174], [64.10330268,-21.83535067],
[64.103093,-21.83535823], [64.10201645,-21.8335867], [64.10023022,-21.83958701],
[64.09996606,-21.83075159], [64.09956085,-21.8318533], [64.09969393,-21.83135472],
[64.09966419,-21.83283152], [64.09927966,-21.83443047], [64.09897629,-21.83442713],
[64.09932679,-21.83527502], [64.09836098,-21.82838524], [64.09697357,-21.8344883],
[64.09639285,-21.83645335], [64.0976964,-21.82819404], [64.09655877,-21.8297125],
[64.0995854,-21.82831304], [64.09962577,-21.82796193], [64.1010404,-21.82658301],
[64.0987423,-21.82324136], [64.1004515,-21.82264013], [64.09870091,-21.82059901],
[64.10043852,-21.8196916], [64.10044536,-21.81733748], [64.09891215,-21.81518584],
[64.10091765,-21.81389396], [64.10120764,-21.81404255], [64.10106234,-21.81669438],
[64.10108913,-21.81905206], [64.10107865,-21.82486569], [64.10121584,-21.82607805],
[64.10305008,-21.81554252], [64.10163964,-21.8157309], [64.10201546,-21.81746768],
[64.10199035,-21.81712289], [64.10172319,-21.82997977], [64.10175862,-21.83035388],
[64.10172493,-21.82369894], [64.14776775,-21.93301258], [64.14792521,-21.93208067],
[64.14717391,-21.92830483], [64.14636693,-21.92145852], [64.14585067,-21.91984843],
[64.14764814,-21.92569804], [64.1479993,-21.92979801], [64.14556371,-21.9216959],
[64.1452525,-21.92140085], [64.14491041,-21.92161674], [64.1451478,-21.92242831],
[64.14504704,-21.92157659], [64.1454139,-21.924413], [64.1456108,-21.92475708],
[64.14571941,-21.9256742], [64.14586952,-21.9264021], [64.14606975,-21.927429],
[64.14640643,-21.92864472], [64.14704354,-21.93184881], [64.1468064,-21.93119348],
[64.14716035,-21.933163], [64.14721943,-21.93281504], [64.14779265,-21.93312215],
[64.14749149,-21.9348132], [64.1451403,-21.94422139], [64.14562473,-21.94365835],
[64.14584758,-21.94348352], [64.14647783,-21.9426119], [64.14636056,-21.94123763],
[64.14613231,-21.94110657], [64.1459759,-21.94038622], [64.14587221,-21.94030933],
[64.14570754,-21.94040386], [64.14530668,-21.93944186], [64.14552503,-21.93932475],
[64.10146448,-21.82369653], [64.10173592,-21.82411149], [64.10169626,-21.8230797],
[64.10234091,-21.823896], [64.10449428,-21.81753954], [64.10434476,-21.81655303],
[64.10464106,-21.81674663], [64.1050672,-21.81706509], [64.10474231,-21.81486249],
[64.14447476,-21.93981783], [64.14377211,-21.9397533], [64.14380328,-21.94010157],
[64.14367429,-21.93945698], [64.14353176,-21.94021918], [64.14338973,-21.93936229],
[64.14308501,-21.94004382], [64.14285745,-21.94122026], [64.14322122,-21.94035413],
[64.14322487,-21.93927742], [64.13989508,-21.93849626], [64.14553763,-21.93869035],
[64.14557999,-21.9381959], [64.14583528,-21.93826521], [64.1461397,-21.93778954],
[64.14644332,-21.93727716], [64.14610177,-21.93849982], [64.14639551,-21.93790853],
[64.14672912,-21.93675997], [64.14677626,-21.93648584], [64.14688593,-21.93649564],
[64.14695829,-21.93624711], [64.14552427,-21.93823164], [64.14597412,-21.93648194],
[64.14627653,-21.93573849], [64.14937233,-21.93944688], [64.14944429,-21.93875976],
[64.14823125,-21.93823022], [64.10825887,-21.82113879], [64.10791526,-21.82113944],
[64.10877689,-21.8210318], [64.10776555,-21.81581854], [64.10787068,-21.81601942],
[64.10773277,-21.80961276], [64.10783954,-21.80887436], [64.10880379,-21.80873914],
[64.11027571,-21.82093912], [64.11072537,-21.81996366], [64.11036079,-21.82224652],
[64.11196131,-21.82259923], [64.11161468,-21.81777942], [64.11168999,-21.81845482],
[64.11060525,-21.81535823], [64.11041792,-21.81559483], [64.10945786,-21.80932836],
[64.11027127,-21.80965986], [64.11016485,-21.80969435], [64.10899728,-21.80445206],
[64.10918148,-21.80436209], [64.10879707,-21.80708407], [64.10868413,-21.81259266],
[64.10848617,-21.81397382], [64.11544609,-21.82748363], [64.11571991,-21.82363848],
[64.11535419,-21.83390993], [64.11608368,-21.83710948], [64.12218354,-21.83982808],
[64.12204948,-21.83916684], [64.11783299,-21.8274011], [64.12226695,-21.84021798],
[64.12356749,-21.82395189], [64.12319576,-21.8257466], [64.12133262,-21.82779453],
[64.1218001,-21.82616039], [64.12127139,-21.81714308], [64.12116322,-21.81698291],
[64.12139686,-21.81801734], [64.14809197,-21.93818703], [64.14753394,-21.93993512],
[64.14748965,-21.93967137], [64.14772076,-21.9409737], [64.14730229,-21.94148048],
[64.14740645,-21.94153403], [64.14840591,-21.94133289], [64.14869591,-21.94306248],
[64.14828404,-21.94359163], [64.14786845,-21.94332628], [64.13574256,-21.89650364],
[64.14295045,-21.91395353], [64.14309963,-21.91351593], [64.14323069,-21.91382684],
[64.1431236,-21.91460225], [64.14327824,-21.91391297], [64.14316622,-21.9148781],
[64.14294284,-21.912685], [64.14292534,-21.91362733], [64.14275626,-21.91282098],
[64.1431231,-21.912996], [64.15061313,-21.88878544], [64.13368785,-21.85577974],
[64.13586868,-21.85138451], [64.13868755,-21.85098391], [64.10532271,-21.85325369],
[64.14447929,-21.79166224], [64.10302257,-21.82638295], [64.1052163,-21.82447085],
[64.10811043,-21.82196654], [64.10171007,-21.77089895], [64.10249271,-21.77367591],
[64.13936408000001,-21.77932625], [64.13954305,-21.77914826], [64.13661596,-21.78007787],
[64.13525738,-21.80458193], [64.13511915,-21.79981624], [64.13906751,-21.79867479],
[64.14045698,-21.80049316], [64.14248105,-21.79658039], [64.12450859,-21.79839339],
[64.09531191,-21.83613051], [64.09396283,-21.83024184], [64.0944294,-21.82894332],
[64.0955959,-21.82623658], [64.1006529,-21.77137318], [64.09678627,-21.77555202],
[64.09770644,-21.78283829], [64.09887321,-21.77871186], [64.10288316,-21.77432045],
[64.10308535,-21.77225015], [64.10290292,-21.76378757], [64.13992591,-21.82112975],
[64.14775624,-21.9279493], [64.12956134,-21.83593629], [64.14554421,-21.90701645],
[64.1453708,-21.90631749], [64.14671778,-21.87280026], [64.14586786,-21.9010226],
[64.14568975,-21.90121545], [64.14194171,-21.91577047], [64.14210581,-21.91664605],
[64.1447681,-21.89611288], [64.13359318,-21.85392898], [64.13198734,-21.85268137],
[64.13107482,-21.85111969], [64.12857588,-21.85243946], [64.1304265,-21.85446066],
[64.1314937,-21.85600639], [64.13075214,-21.85834829], [64.15404391,-21.78915665],
[64.16204825,-21.74870257], [64.14787093,-21.78090101], [64.14484015,-21.79258677],
[64.14736526,-21.93901311], [64.14737649,-21.93878794], [64.14760367,-21.93873685],
[64.14775079,-21.93961558], [64.14794813,-21.94050139], [64.14799577,-21.9407644],
[64.14786807,-21.94001465], [64.14803377,-21.94094073], [64.14890727,-21.9420504],
[64.14760587,-21.93838626], [64.1474665,-21.93765638], [64.14738569,-21.93704903],
[64.14659632,-21.93999386], [64.1470677,-21.94048128], [64.14713489,-21.93993395],
[64.14716877,-21.93957624], [64.14726838,-21.93980724], [64.14748807,-21.93981921],
[64.14737569,-21.93912446], [64.14742431,-21.93942077], [64.1474435,-21.93952892],
[64.14745934,-21.93962638], [64.14730346,-21.94250199], [64.14743244,-21.94196454],
[64.14830143,-21.94141208], [64.14872058,-21.94178908], [64.14873102,-21.94153372],
[64.14854044,-21.94147559], [64.10176833,-21.77654537]]

export default x;