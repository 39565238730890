import react from 'react'

import {useState} from 'react'
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import Button from '@mui/material/Button'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = react.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
  

function Recorder(props) {
    const {myCoords} = props;
    // const [blobUrl, setBlobURL] = useState(null);
    const [isBlocked, setIsBlocked] = useState(false);

    const [open, setOpen] = useState(false);
    const [binstate, setBinState] = useState(null);
    // const handleClick = () => {
    //     setOpen(true);
    // };
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };




    navigator.mediaDevices.getUserMedia({ audio: true },
        () => {
          console.log('Permission Granted');
          setIsBlocked(false);
        },
        () => {
          console.log('Permission Denied');
          setIsBlocked(true);
        },
    );
    const [recordingstate, setRecordingstate] = useState(null);
    const sendRecording = (data)=>{
        let formData = new FormData();
        formData.append('recording', data);
        formData.append('user', 'benedikt');
        formData.append('gps_lon', myCoords.longitude);
        formData.append('gps_lat', myCoords.latitude);   
        fetch('https://api.landnemi.com/api/upload_recording', {
            'method': 'POST',
            'body': formData
        }).then(x=>{
            x.text().then(x=>{
                setOpen(true);
                setBinState(x);
            })
        });
    }

    console.log(myCoords);

    const start = (e)=>{
        e.preventDefault();
        setRecordingstate(RecordState.START);
    }
    const stop = (e) => {
        e.preventDefault();
        setRecordingstate(RecordState.STOP);
    }
    const recorderStop = (data) => {
        console.log(data.blob.size);
        // setBlobURL(data.url);
        let a = document.createElement('audio');
        a.src = data.url;
        let d = a.duration;
        a.remove();
        // if recording is shorter than 1 second abort sending it. z
        if(d<0.2){return;}
        sendRecording(data.blob)
    }
    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical:'top', horizontal:'right'}}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {binstate}
                </Alert>
            </Snackbar>
            <div style={{position:'absolute', bottom:10,left:10,zIndex:100000}}>
                <AudioReactRecorder state={recordingstate} onStop={recorderStop} canvasHeight={0} canvasWidth={0}/>
                <Button variant='contained' disabled={myCoords===null || isBlocked} color={recordingstate===RecordState.START?'error':'success'} onMouseDown={start} onMouseUp={stop} onTouchStart={start} onTouchEnd={stop}  ><KeyboardVoiceIcon /></Button>
            </div>
        </>
    );
}

export default Recorder;
