import './App.css';
import react from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { renderToStaticMarkup } from "react-dom/server";
import { MapContainer, TileLayer, Marker, Polyline, Popup} from 'react-leaflet'
import L from 'leaflet'
import DeleteIcon from '@mui/icons-material/Delete';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import data from './Assets/fake_data.js'
import {useState} from 'react'
import Recorder from './Components/Recorder'
import BinMarkerPopup from './Components/BinMarkerPopup';
import { Button } from '@mui/material';
import {getDistance} from 'geolib';

// Make fake levels loosely based on geolocation.
const levels = ['red', 'yellow', 'green'];
const fakeLevel = (lat, lon)=>{
  let t=lat+lon;
  if( t%0.5 < 0.05){return 0;}
  if( t%0.5 < 0.1){return 1;}
  return 2;
}

const getBinIcon = (c)=>L.divIcon({html: renderToStaticMarkup(<DeleteIcon style={{color: c, backgroundColor:'white',borderRadius:'100px', border: '1px solid black'}}/>) });

const getPersonIcon = ()=>L.divIcon({html: renderToStaticMarkup(<PersonPinCircleIcon style={{color: 'blue'}}/>) });

const fakeRecords = [
  {timestamp: Date.now(), 'user': 'benni', 'data':'full'},
  {timestamp: Date.now(), 'user': 'benni', 'data':'full'},
  {timestamp: Date.now(), 'user': 'benni', 'data':'full'},
  {timestamp: Date.now(), 'user': 'benni', 'data':'full'},
  {timestamp: Date.now(), 'user': 'benni', 'data':'full'},
  {timestamp: Date.now(), 'user': 'benni', 'data':'full'},
  {timestamp: Date.now(), 'user': 'benni', 'data':'full'},
  {timestamp: Date.now(), 'user': 'benni', 'data':'full'},
  {timestamp: Date.now(), 'user': 'benni', 'data':'full'},
  {timestamp: Date.now(), 'user': 'benni', 'data':'full'},
  {timestamp: Date.now(), 'user': 'benni', 'data':'full'},
  {timestamp: Date.now(), 'user': 'benni', 'data':'full'}
]

const createClusterCustomIcon = function (cluster) {
  return L.divIcon({
    html: `<div>${cluster.getChildCount()} ${renderToStaticMarkup(<DeleteIcon />)} </div>`,
    className: 'marker-cluster-custom',
    iconSize: L.point(40, 40, true),
  });
};

const eucDistanceObject = (p1, p2) => {
  return eucDistance([p1.latitude, p1.longitude], [p2.latitude, p2.longitude])
}

const eucDistance = (p1, p2) => {
  let d =  getDistance([p1[0], p1[1]], p2)
  if(d!==d){
    return 1000000000;
  }
  return d;
}

const findNearestBin = (coords, listOfBins) => {
  if (coords === null) { return null; }
  let l = listOfBins.map(bin=>eucDistance([coords.latitude, coords.longitude], bin));
  const min = Math.min.apply(Math,l);
  return listOfBins[l.indexOf(min)];
}

function App() {
  let center = [64.128288, -21.827774];
  const [map, setMap] = useState(null);
  const [nearestBin, setNearestBin] = useState(null);
  const [lastChangeLocation, setLastChangeLocation] = useState(Date.now());
  const [myCoords, setMyCoords] = useState(null);
  // move the map to the user.
  const move = ()=>{
    if(map === null){
      return;
    }
    map.flyTo(L.latLng(myCoords.latitude, myCoords.longitude), 18);
  }
  // Get the coordinates of the device.
  const successHandler = position => {
    console.log('This should not be called often');
    setMyCoords(position.coords)
    setNearestBin(findNearestBin(myCoords, data))
  };
  const errorHandler = error => console.error(error.message)
  navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
  // watch position change overtime.  
  const onPositionChange = ({coords}) => { 
    if (myCoords !== null){
      if( eucDistanceObject(myCoords, coords) < 500 ){return;}
    }
    if(lastChangeLocation - Date.now() < 3000){ return; }
    console.log("Location has changed");
    setLastChangeLocation(Date.now())
    setMyCoords(coords);
    setNearestBin(findNearestBin(myCoords, data))
  } 
  navigator.geolocation.watchPosition(onPositionChange, errorHandler);

  return (
    <div className="App">
      <Recorder myCoord={myCoords}/>
      {/* Button that moves the map to the coordinates of the device. */}
      <Button variant='contained' color='primary' style={{position: 'absolute', top:5, left:50, zIndex:100000}} onClick={move} disabled={myCoords === null}><PersonPinCircleIcon/></Button> 
      <div style={{ height: '100vh', width: '100vw' }}>
        {myCoords && <Recorder  myCoords={myCoords}/> }
        <MapContainer zoomSnap={0.1} center={center} zoom={10} preferCanvas={true} style={{height:'100%', width:'100%'}} whenCreated={setMap}>
          <TileLayer 
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
            {data.map((d)=><Marker 
              key={`${d[0]}-${d[1]}+${Math.random()}`} position={[d[0], d[1]]} 
              icon={getBinIcon(`${levels[fakeLevel(d[0], d[1])]}`)} style={{color: levels[fakeLevel(d[0], d[1])]}}
              eventHandlers={{click: (e) => {console.log('marker clicked', e)},}}
              >
                <BinMarkerPopup coords={{longitude:d[0], latitude:d[1]}} records={fakeRecords}/>
              </Marker>
            )}
          </MarkerClusterGroup>
          {myCoords !== null &&  <Marker position={[myCoords.latitude, myCoords.longitude]} icon={getPersonIcon()} style={{color:'blue'}}>
            <Popup>
              {myCoords.latitude} - {myCoords.longitude}
            </Popup>
          </Marker> }
          
          { (myCoords !== null && nearestBin !== null) && <Polyline positions={[ [myCoords.latitude, myCoords.longitude], nearestBin]} /> }

        </MapContainer>

      </div>
    </div>
  );
}

export default App;
