import react, { useState } from 'react'
import Table from '@mui/material/Table'

import {Popup} from 'react-leaflet'
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

function BinMarkerPopup(props) {

    const [data, setData] = useState(null);

    const {coords, records} = props;
    const foo = ()=>{
        console.log('foo');
    }
    return (
        <Popup onOpen={foo}>
            <div>Coordinates: {coords.longitude} longitude, {coords.latitude} latitude</div>
            <TableContainer component={Paper}>
                <Table style={{width: '100%'}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">Timestamp</TableCell>
                            <TableCell align="right">User</TableCell>
                            <TableCell align="right">Data</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.slice(0,5).map(row=><TableRow key={`${Math.random()} + ${Math.random()}`} >
                            <TableCell>{Date(row.timestamp)}</TableCell>
                            <TableCell>{row.user}</TableCell>
                            <TableCell>{row.data}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>


</Popup>
    );
}

export default BinMarkerPopup;
